@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Heebo", sans-serif;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}